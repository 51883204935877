import React from "react"

import Header from "./header"
import "../sass/main.scss"

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <div style={{ paddingTop: 60 }}>
        <main>{children}</main>
        <footer className="footer-bg">
          <div id="contact">
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-xs-12 col-sm-12 footer-text">
                  <h3>Kontakt</h3>

                  <p>
                    @: <a href="mailto:biuro@jarglas.pl">biuro@jarglas.pl</a>
                  </p>

                  <p>
                    p: <a href="tel:+48693110571">+48 693 110 571 </a>
                  </p>

                  <p>
                    ul. Racławicka 16
                    <br />
                    37-500 Jarosław
                  </p>
                  <div className="social-flex">
                    <a href="https://www.facebook.com/LustraJarglass/">
                      <img
                        className="img-responsive"
                        alt="Facebook"
                        src="../img/facebook.svg"
                      />
                    </a>
                    <a href="https://www.instagram.com/jarglass/">
                      <img
                        className="img-responsive"
                        alt="Instagram"
                        src="../img/instagram.svg"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  )
}

export default Layout
