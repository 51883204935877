import React from "react"
import Navbar from "react-bootstrap/lib/Navbar"
import Nav from "react-bootstrap/lib/Nav"
import NavItem from "react-bootstrap/lib/NavItem"

const Header = () => (
  <header>
    <Navbar className="navbar navbar-default" collapseOnSelect>
      <Navbar.Header>
        <Navbar.Brand>
          <a className="navbar-brand" href="/">
            <img
              src="../img/jarglas-logo.png"
              alt="logo-jarglas"
              className="navbar-brand-logo"
            />
          </a>
        </Navbar.Brand>
        <Navbar.Toggle />
      </Navbar.Header>
      <Navbar.Collapse>
        <Nav pullRight>
          <NavItem eventKey={1} href="/">
            HOME
          </NavItem>
          <NavItem eventKey={2} href="/#about">
            O NAS
          </NavItem>
          <NavItem eventKey={3} href="/#clients">
            KLIENCI
          </NavItem>
          <NavItem eventKey={4} href="/#realisations">
            REALIZACJE
          </NavItem>
          <NavItem eventKey={5} href="/products-page/">
            PRODUKTY
          </NavItem>
          <NavItem eventKey={6} href="/#contact">
            KONTAKT
          </NavItem>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  </header>
)

export default Header
